import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Container from "../components/container";
import { FaBook, FaCamera, FaQuestionCircle } from "react-icons/fa";
import { Link } from "gatsby";
import styles from "./baza-wiedzy.module.css";
import SEO from "../components/seo";

const BazaWiedzy = () => {
  return (
    <Layout>
      <SEO
        title="CRM - blog. Kopalnia informacji dla spragnionych wiedzy."
        description="Baza wiedzy o CRM • Wiedza o korzyściach płynących z CRM • Poradnik dla małej firmy • Materiały video - zobacz, jak CRM może wpłynąć na Twojej firmy!"
        keywords={["baza wiedzy o crm"]}
      />
      <Hero maxHeight="280px" justifyContent="center">
        <h1>Prosty CRM — baza wiedzy</h1>
        <p>Kopalnia informacji o systemie CRM</p>
      </Hero>
      <Container>
        <div className={styles.cardContainer}>
          <div>
            <div>
              <FaBook size="45px" />
              <h4>Wiedza o CRM</h4>
              <p>
                QuickCRM powstał dzięki naszemu doświadczeniu. To jest miejsce,
                w którym chcemy się nim z Tobą podzielić!
              </p>
            </div>
            <Link to="/wiedza-o-crm/">Przejdź do bazy wiedzy</Link>
          </div>
          <div>
            <div>
              <FaCamera size="45px" />
              <h4>Materiały video</h4>
              <p>
                Dzięki materiałom video poznasz funkcje systemu QuickCRM i
                dowiesz się, jak łatwo możesz je wykorzystać w swojej firmie.
              </p>
            </div>
            <Link to="/materialy-video/">Przejdź do materiałów</Link>
          </div>
          <div>
            <div>
              <FaQuestionCircle size="45px" />
              <h4>Pomoc</h4>
              <p>
                Szukasz odpowiedzi? Chcesz poznać możliwości systemu? Zapoznaj
                się z materiałami dotyczącymi obsługi systemu.
              </p>
            </div>
            <Link to="/pomoc/">Przejdź do pomocy</Link>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default BazaWiedzy;
